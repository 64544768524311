import React from "react";
import { Helmet } from "react-helmet";
import SevenDaysBanner from '../components/SevenDaysBanner';
import DefaultHero from "../components/DefaultHero";
import Footer from '../components/Footer';
import { StaticQuery, graphql } from "gatsby";

export const HeroElement = ({context}) => {
    return (
        <div className="homepage">
            <h1 className="top">Ihr Video-Chat mit unseren Sicherheits-Experten</h1>
            <h2>Jetzt Video Button klicken, um den Video-Chat zu starten.</h2>
            <div className="callus">
                <call-us id="callus"
                    phonesystem-url="https://meinalarm24.3cx.excloud.de/"
                    party="meinalarm24"
                    minimized="true"
                    allow-call="true"
                    allow-video="true"
                    authentication="name"
                    operator-name="MeinAlarm24 Sicherheits-Experte"
                    invite-message="Herzlichen Dank für Ihre Video-Chat Anfrage! Sind Sie startklar?"></call-us>
            </div>
        </div>
    );
};

export default () => {
  return (
    <StaticQuery
        query={graphql`
        query VideoChatQuery {
            site {
                siteMetadata {
                  title
                  image
                  image768
                  image1024
                  image1216
                  image1408
                }
            }
      }`}
      render={data => {
        const contextData = {
            title: data.site.siteMetadata.title,
            image: '/img/hero/' + data.site.siteMetadata.image,
            image768: '/img/hero/'  + data.site.siteMetadata.image768,
            image1024: '/img/hero/' + data.site.siteMetadata.image1024,
            image1216: '/img/hero/' + data.site.siteMetadata.image1216,
            image1408: '/img/hero/' + data.site.siteMetadata.image1408,
            showPriceCalculator: false,
            showHeroMobile: false,
            showBottom: true,
            disableBackground: true,
            defaultCssClass: 'video-chat-hero'
        };
        contextData.showElement = <HeroElement context={contextData}/>;
        return (
        <div>
            <Helmet>
                <script src={'/js/callus.js'} />
                <html className='has-navbar-fixed-top' />
                <title>Video-Chat mit einem Sicherheits-Experten</title>
                <meta name="description" content="Per Video-Chat lassen sich die meisten Ihrer Fragen klären. Rufen Sie uns an. Unsere Sicherheits-Experten stehen an 7 Tagen in der Woche für Sie zur Verfügung. " />
                <meta name="keywords" content="" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content='Video-Chat mit einem Sicherheits-Experten | MeinAlarm24' />
                <meta property="og:description" content="Per Video-Chat lassen sich die meisten Ihrer Fragen klären. Rufen Sie uns an. Unsere Sicherheits-Experten stehen an 7 Tagen in der Woche für Sie zur Verfügung. " />
                <meta property="og:image" content={data.site.siteMetadata.image} />
            </Helmet>
            <DefaultHero context={contextData}/>
            <SevenDaysBanner />
            <Footer />
        </div>
        )}
    }
    />
  );
};
